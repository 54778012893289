import React from "react"

import Layout from "../components/layout/layout"
import Contact from "../components/contact/contact"
import SEO from "../components/seo"

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <Contact />
  </Layout>
)

export default ContactPage
