import React from "react"
import "./contact.less"

import Button from "../../components/ui/button/button"

const Contact = () => (
  <section className="contact">
    <h1>Contact me</h1>
    <p>Contact me if you want to know more about me or my projects. I'll get back to you as soon as possible.</p>
    <form name="contact" method="POST" action="/success/" data-netlify="true" netlify-honeypot="bot-field">
      <input type="hidden" name="form-name" value="contact" aria-label="form-name" />

      <label for="bot-field" className="honey">
        Don’t fill this out if you're human:{" "}
        <input id="bot-field" className="honey" type="text" name="bot-field" aria-label="Don’t fill this out if you're human" />
      </label>

      <label for="name">
        Your Name: <input id="name" type="text" name="name" required aria-label="name" />
      </label>

      <label for="email">
        Your Email: <input id="email" type="email" name="email" required aria-label="email" />
      </label>

      <label for="message">
        Message: <textarea id="message" type="text" rows="10" name="message" required aria-label="message" />
      </label>

      <div>
        <Button primary type="submit" title="submit contact form">
          Send
        </Button>
      </div>
    </form>
  </section>
)

export default Contact
